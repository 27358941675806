import React, { useState } from 'react';
import './home.css';

const slides = [
  { id: 1, content: 'Slide 1', backgroundColor: '#FF5733' },
  { id: 2, content: 'Slide 2', backgroundColor: '#33FF57' },
  { id: 3, content: 'Slide 3', backgroundColor: '#3357FF' }
];

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
  };

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  const getSlideContent = (index) => {
    return slides[index] ? slides[index].content : 'No slide';
  };

  const getSlideBackgroundColor = (index) => {
    return slides[index] ? slides[index].backgroundColor : '#fff';
  };

  return (
    <div className="sliderMain">
    <div className="slider-container">
      <div
        className="before-slide"
        style={{ backgroundColor: getSlideBackgroundColor((currentSlide - 1 + slides.length) % slides.length) }}
      >
        {getSlideContent((currentSlide - 1 + slides.length) % slides.length)}
      </div>
      <div className="slider">
        {slides.map((slide, index) => (
          <div
            key={slide.id}
            className={`slide ${index === currentSlide ? 'active' : ''}`}
            style={{ backgroundColor: slide.backgroundColor }}
          >
            {slide.content}
          </div>
        ))}
      </div>
      <div
        className="after-slide"
        style={{ backgroundColor: getSlideBackgroundColor((currentSlide + 1) % slides.length) }}
      >
        {getSlideContent((currentSlide + 1) % slides.length)}
      </div>
      <button className="prev" onClick={prevSlide}>❮</button>
      <button className="next" onClick={nextSlide}>❯</button>
      {/* <div className="nav-dots">
        {slides.map((_, index) => (
          <div
            key={index}
            className={`nav-dot ${index === currentSlide ? 'active' : ''}`}
            onClick={() => goToSlide(index)}
          ></div>
        ))}
      </div> */}
    </div>
     <div className="nav-dots">
     {slides.map((_, index) => (
       <div
         key={index}
         className={`nav-dot ${index === currentSlide ? 'active' : ''}`}
         onClick={() => goToSlide(index)}
       ></div>
     ))}
   </div>
   </div>
  );
};

export default Slider;
