import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./blog.css";

import imagg from "../../assets/background.jpeg";
import authorAvatar from "../../assets/icons8-avatar-64.png";

import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";

function PaginatedPosts({ blogData }) {
  let posts = blogData["results"] || [];
  const totalPages = blogData["total_pages"] || 1;

  const [currentPage, setCurrentPage] = useState(1);

  const calculateWordCount = (text) => {
    const strippedText = text.replace(/<[^>]*>/g, ""); // Remove HTML tags
    const words = strippedText.trim().split(/\s+/).length; // Split by whitespace and count words
    return words;
  };

  // Function to estimate read time based on word count
  const calculateReadTime = (wordCount) => {
    const wordsPerMinute = 200; // Average reading speed
    const readTime = Math.ceil(wordCount / wordsPerMinute); // Round up to nearest minute
    return readTime;
  };

  const getPosts = async (query, page = 1) => {
    try {
      let url = `${process.env.REACT_APP_BASE_URL}api/posts/?page=${page}`;
      if (query !== "" && query !== undefined) {
        url += `&query=${query}`;
      }

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        posts = data["results"];
        // console.log(posts)
      } else {
        const errorText = await response.text();
        console.error(`Error fetching posts: ${errorText}`);
      }
    } catch (error) {
      console.error(`Error getting posts: ${error}`);
    } finally {
      //setLoading(false); // Ensure loading is set to false after fetching
    }
  };
  function handlePageChange(page) {
    setCurrentPage(page);
    getPosts("", page);
  }

  return (
    <>
      <>
        <div className="blogs">
          {posts.length === 0 && (
            <span className="notFound">No posts found !! </span>
          )}
          {posts.map((post) => {
            const wordCount = calculateWordCount(post.content); // Calculate word count for the post content
            const readTime = calculateReadTime(wordCount); // Calculate estimated read time

            return (
              <div key={post.id} className="blog">
                <Link key={post.id} to={`/post/${post.id}`}>
                  <img
                    className="blogThumb"
                    src={post.image || imagg}
                    alt={post.title || "Image"}
                  />
                  <div className="bInfo">
                    <div className="authorCard">
                      <img
                        src={post.author.image || authorAvatar}
                        alt={post.author.username}
                      />
                      <div className="acPost">
                        <h3 className="bAuthor">{post.author.username}</h3>
                        <div className="acpP">
                          <h4 className="date">
                            {post.created_at.split("T")[0]}
                          </h4>
                          <div className="dot"></div>
                          <h4 className="date">{readTime} min read</h4>
                        </div>
                      </div>
                    </div>
                    <h2 className="title">{post.title}</h2>
                    <p
                      className="content"
                      dangerouslySetInnerHTML={{ __html: post.content }}
                    ></p>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </>
      {posts.length > 0 && (
        <div className="paginationFlex">
          <h2>
            Page <span>({currentPage})</span> of <span>({totalPages})</span>
          </h2>
          <ResponsivePagination
            current={currentPage}
            total={totalPages}
            onPageChange={(page) => handlePageChange(page)}
          />
        </div>
      )}
    </>
  );
}

export default PaginatedPosts;
