import React, { useEffect, useState } from "react";
import { useParams, Outlet, Link } from "react-router-dom";
import "./blog.css";

import blogHero from "../../assets/blogHero.webp";

const SinglePost = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { postId } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getPost = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/posts/${postId}/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response.ok) {
        const postData = await response.json();
        setPost(postData);
        // console.log(postData)
        setLoading(false);
      } else {
        const errorText = await response.text();
        const errorData = JSON.parse(errorText);
        setError(`Error fetching post: ${errorData.error}`);
        setLoading(false);
      }
    } catch (error) {
      setError(`Error getting post: ${error}`);
      setLoading(false);
    }
  };

  useEffect(() => {
    getPost();
  }, [postId]);

  if (loading) {
    return (
      <div className="bgLoader">
        <div className="loading loader"></div>
      </div>
    );
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="singlePost">
      {post ? (
        <div className="contentFlex">
          <div>
            <h1 className="classOne">{post.title}</h1>
            <div className="authorCard">
              <div>{post.author.username}</div>
              <div>{new Date(post.created_at).toLocaleString()}</div>
            </div>
            <div className="tImg tImgsingle">
              <img
                src={`${process.env.REACT_APP_BASE_URL}${post.image}`}
                alt="dasce blog home image"
              />
            </div>
            {/* {post.image && <img src={post.image} alt={post.title} />} */}
            <h2
              className="pContent"
              dangerouslySetInnerHTML={{ __html: post.content }}
            ></h2>
            {/* <div className="separator"></div> */}

            {/* <div>Likes: {post.total_likes}</div> */}
            {/* <div>Created at: {new Date(post.created_at).toLocaleString()}</div> */}
            {/* <Link to="comment">View/Add Comments</Link>
          <Outlet context={{ postId }} /> */}
          </div>
          <div className=""></div>
        </div>
      ) : (
        <p>Post not found.</p>
      )}
    </div>
  );
};

export default SinglePost;
